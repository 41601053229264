import React from "react";

const AcceptableUsePolicy = () => {
  const styles = {
    container: {
      fontFamily: "Arial, sans-serif",
      maxWidth: "800px",
      margin: "0 auto",
      padding: "100px 20px",
      lineHeight: "1.6",
      color: "#333",
    },
    heading: {
      textAlign: "center",
      color: "#2c3e50",
    },
    section: {
      marginBottom: "20px",
    },
    list: {
      paddingLeft: "20px",
    },
  };

  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>Supermart Acceptable Use Policy</h1>
      <p><strong>Effective Date:</strong> 30/01/2025</p>
      
      <div style={styles.section}>
        <h2>1. General Conduct</h2>
        <p>Vendors and delivery agents must:</p>
        <ul style={styles.list}>
          <li>Conduct business in a professional, honest, and ethical manner.</li>
          <li>Comply with all applicable laws and regulations.</li>
          <li>Respect all users of the platform, including customers and Supermart staff.</li>
          <li>Report any suspicious activities affecting platform integrity.</li>
        </ul>
      </div>
      
      <div style={styles.section}>
        <h2>2. Prohibited Activities</h2>
        <ul style={styles.list}>
          <li>Fraudulent transactions, false advertising, or unauthorized processing.</li>
          <li>Sale of illegal, counterfeit, expired, or hazardous products.</li>
          <li>Abusive, threatening, or discriminatory behavior.</li>
          <li>Manipulating reviews or ratings.</li>
          <li>Unauthorized data collection or misuse of customer information.</li>
          <li>Conducting transactions outside Supermart to evade service fees.</li>
        </ul>
      </div>
      
      <div style={styles.section}>
        <h2>3. Vendor Responsibilities</h2>
        <ul style={styles.list}>
          <li>Provide accurate product details and pricing.</li>
          <li>Deliver quality products on time.</li>
          <li>Honor return and refund policies.</li>
        </ul>
      </div>

      <div style={styles.section}>
        <h2>4. Delivery Agent Responsibilities</h2>
        <ul style={styles.list}>
          <li>Deliver orders promptly and handle goods with care.</li>
          <li>Follow designated routes and traffic laws.</li>
          <li>Communicate with customers professionally.</li>
        </ul>
      </div>
      
      <div style={styles.section}>
        <h2>5. Consequences of Policy Violations</h2>
        <p>Violating this policy may result in warnings, suspension, permanent removal, or legal action.</p>
      </div>
      
      <div style={styles.section}>
        <h2>6. Reporting Violations</h2>
        <p>Report policy violations to Supermart support.</p>
      </div>
      
      <div style={styles.section}>
        <h2>7. Contact Us</h2>
        <p><strong>Email:</strong> support@supermart.com.ng</p>
        <p><strong>Customer Support Chat:</strong> Available in the Supermart app.</p>
      </div>
    </div>
  );
};

export default AcceptableUsePolicy;
