import React from "react";

const RefundPolicy = () => {
  const styles = {
    container: {
      maxWidth: "800px",
      margin: "20px auto",
      padding: "100px 20px",
      fontFamily: "Arial, sans-serif",
      backgroundColor: "#f9f9f9",
      borderRadius: "8px",
      boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
    },
    heading: {
      color: "#333",
      borderBottom: "2px solid #ddd",
      paddingBottom: "5px",
    },
    section: {
      marginBottom: "15px",
    },
    list: {
      paddingLeft: "20px",
    },
    contact: {
      marginTop: "15px",
      fontWeight: "bold",
    }
  };

  return (
    <div style={styles.container}>
      <h2 style={styles.heading}>Supermart Refund Policy</h2>
      <p><strong>Effective Date:</strong> 30/01/2025</p>
      <p>At Supermart, we are committed to ensuring a seamless shopping experience for our customers. If you are not satisfied with your order, our refund policy outlines the conditions under which refunds are processed.</p>
      
      <h3 style={styles.heading}>1. Eligibility for Refunds</h3>
      <ul style={styles.list}>
        <li><strong>Order Cancellation:</strong> If you cancel your order before it is processed by the vendor.</li>
        <li><strong>Non-Delivery of Order:</strong> If the order is not delivered within the estimated timeframe due to vendor or delivery agent issues.</li>
        <li><strong>Incorrect or Damaged Items:</strong> If the delivered items are incorrect, defective, or damaged upon arrival.</li>
        <li><strong>Expired Products:</strong> If the product received is past its expiration date at the time of delivery.</li>
      </ul>

      <h3 style={styles.heading}>2. Refund Request Process</h3>
      <p>To request a refund, customers must:</p>
      <ol style={styles.list}>
        <li><strong>Report the Issue:</strong> Submit a complaint through the app within 24 hours of receiving the order.</li>
        <li><strong>Provide Proof:</strong> Attach clear images of the incorrect, damaged, or expired items.</li>
        <li><strong>Review Process:</strong> Our team will review the claim and respond within 72 hours.</li>
      </ol>

      <h3 style={styles.heading}>3. Refund Method & Timeline</h3>
      <p>Approved refunds will be processed within <strong>24 hours</strong> of approval.</p>
      <p>Refunds will be issued via the original payment method or as store credit, depending on the customer’s preference.</p>

      <h3 style={styles.heading}>4. Non-Refundable Items</h3>
      <ul style={styles.list}>
        <li>Perishable goods such as fresh food, dairy, and bakery items unless expired or damaged upon delivery.</li>
        <li>Digital products or services once they have been accessed or used.</li>
        <li>Orders where the complaint is submitted beyond the specified timeframe.</li>
      </ul>

      <h3 style={styles.heading}>5. Vendor and Delivery Agent Responsibility</h3>
      <p>Vendors must ensure that products are accurately listed and in good condition before dispatch.</p>
      <p>Delivery agents are responsible for handling products with care to prevent damage during transit.</p>

      <h3 style={styles.heading}>6. Dispute Resolution</h3>
      <p>If a refund request is disputed by the vendor or delivery agent, Supermart will mediate and make a fair determination based on the provided evidence.</p>

      <h3 style={styles.heading}>7. Contact Us</h3>
      <p style={styles.contact}>For refund inquiries, please reach out to our support team via:</p>
      <p><strong>Email:</strong> support@supermart.com.ng</p>
      <p><strong>Customer Support Chat:</strong> Available in the Supermart app.</p>
    </div>
  );
};

export default RefundPolicy;
